@font-face {
    font-family: CorisandeBold;
    src: url(corisandeFont/CorisandeLight.ttf);
}

*{
	font-family: CorisandeBold, serif;
}
.App {
  text-align: center;
}
body{
	overflow: hidden;
} 

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#nav-top{
	margin-left: 190px;
	height:70px;
	border-bottom:1px solid #dee2e6;
	background: transparent;
}

.nav-topCollase{
	margin-left: 70px !important;
} 

#nav-top a{
	color:#00131DCC;
	font-weight: normal;
	flex: 1 1 auto;
	padding-top: 23px;
    text-align: center;
    display: block;
	font-size: 18px;
}

#nav-top a:hover{
	color:#001188;
	font-weight: normal;
	background: transparent;
	border: none;
}

.nava{
	color:#00131DCC;
	font-weight: normal;
}

.nava:hover{
	color:#001188 !important;	
}

.nav-tabs .nav-link{
	border:none;
	border-bottom:#001188;
}
.nav-tabs .nav-item{
	margin-bottom:0px;
	padding-bottom: 15px;
	font-size:18px;
}
.nav-fill #nav-support-tab{
	margin-top:15px;
}

.nav-fill #nav-controlPanel-tab{
	margin-top:15px;
}

.nav-fill #nav-administracion-tab{
	margin-top:15px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active .nava{
	color:#001188;
	font-weight:bold;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
	border-bottom: 4px solid #001188;
	font-weight:bold;
}
.fixed-top{
	margin-left:220px;
	margin-right: 300px;
	min-width:0px;
	height: 70px;
}

.fixed-topCollapse{
	margin-left: 100px;
} 

.bodyContent{
	width: 100%;
    display: block;
    overflow: auto;
    max-height: 550px;
    overflow-x: hidden;
}

.active{
	color: #001188;
	font-weight: bold;
}

.mzateLogo{
	border-bottom:none;
	margin-left: -6px;
}

.mzateCollapseLogo{
	margin-left: -7px;
    margin-top: -4px;
} 

.btnStandard{
	background-color:#00131D26 !important;
	border:none !important;
	height: 27px !important;
	border-radius:8px !important;
	color:#00131DCC !important;
	font-size:14px !important;
	overflow: hidden;
}

.btnStandard:hover{
	background-color:#001188 !important;
	color:white !important;
}

.inputStandard{
	height:27px !important;
    border-radius:10px !important;
}

.ulSidebar{
	padding-inline-start: 0px;
}

.ulSidebar div svg{
	font-size: 26px;
}

.ulSidebar a{
	width: 192px;
} 

.liSidebar{
	position:relative;
	transition: width 0.3s;
	width:190px;
	max-width:190px;
	cursor:pointer;
	background-repeat: no-repeat;
	background-position: bottom;
	background-size: 100%; 
}

.desplegable{
	background:#060401;
}

.desplegable p{
	color:white;
	padding-left: 15px;
	opacity:0.7;
	font-size: 15px;
	margin-bottom: 0;
    padding-top: 5px;
}

.desplegable p:hover{
	color:white;
	opacity:7;
	cursor:pointer;
}

.desplegable p.active{
	color:white;
	background: #060401 ;
	opacity:7;
	font-weight: bold;
	font-size: 15px;
}
.sidebar2{
	margin: 0;
	padding: 0;
	transition: width 0.3s;
	max-width: 190px;
	width:190px;
	position: fixed;
	background-color: #060401 ;
	bottom:0;
	left:0;
	overflow:auto;
	top:0px;
	padding-right:5px;
	overflow-x:hidden;
	z-index: 1040;
}
.sidebar2Collapse{
	max-width: 70px;
	width:70px;
} 

.subItem{
	display:none; 
    position:absolute; 
}
.subItem li{
	color:white;
	opacity:0.7;
	
}

.spanItem{
	pointer-events:none;
	padding-left: 10px;
}

.subItem li:hover{
	color:white;
	opacity:7;
	cursor:pointer;
}

.disable{
	display:none;
}
.divSidebar{
	padding-left: 15px;
	padding-top: 5px;
	padding-right: 25px;
}

.imgSidebar{
	pointer-events:none;
	position:absolute;
}

.imgSidebarSUbItem{
	padding-left: 10px;
    padding-top: 10px;
    font-size: 5px;
    pointer-events:none;
	position:absolute;
}

.labelSidebar{
	pointer-events:none;
	margin-left:42px;
	line-height: 1.3;
	cursor:pointer;
	font-size:15px;
	font-weight: bold;
	text-align:left;
	width: 120px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	min-height: 30px;	
}
.sidebar2 a{
	margin-bottom: 3px;
	text-decoration:none;
	cursor:pointer;
	color:white;
	opacity:0.7;
    height: 40px;
	background-color: #060401
}

.sidebar2 a:hover{
	border: none;
	background-color: #060401 ;
	color:white;
	opacity:7;
}

.sidebar2 div a:hover{
	border: none;
	background-color: #060401 ;
	color:white;
	opacity:7;
}


.sidebar2 a.active{
	border: none;
	background-color: #060401 ;
	color:white;
	opacity:7;
}

.sidebar2 a span.active{
	color:white;
	opacity:7;
}

#nav-top a.active{
	color:#001188;
	font-weight: bold;
	border-bottom: 4px solid #001188;
	padding-bottom: 42px; 
}

#nav-top a div span{
	margin-left: 0px;
} 

.sidebar2 a span.inactive{
	color: white;
	opacity:0.7;
}

.sidebar2 a span{
	color:white;
	opacity:0.7;
	font-size:15px;
	cursor: pointer;
	width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
	font-weight: bold;
	margin-left: 10px;
}

.sidebar2 a div{
	pointer-events: none;
	max-width: 190px;
	margin-left: 3px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sidebar2 a:hover label{
	color:white;
}

.sidebar2 li:hover a{
	color:white;
	opacity: 7;
	cursor:pointer;	
}
.tab-pane{
	padding: 0px 20px 0px 210px;
}
.tab-paneCollapse{
	padding: 0px 20px 0px 80px;
} 
.containerCollapse:hover .collapseIcon{
	color:white;
	opacity: 7;
}

.collapseIcon {
	margin-left: 144px;
	pointer-events: none;
	color:white;
	opacity: 0.7;
	font-size: 22px;
}

.noCollapseIcon {
	margin-left: 24px;
	pointer-events: none;
	color:white;
	opacity: 0.7;
	font-size: 22px;
}
.containerCollapse:hover .noCollapseIcon{
	color:white;
	opacity: 7;
}

.containerCollapse{
	cursor:pointer;
	bottom: 0px;
    position: fixed;
    background: #060401 ;
    max-width: 190px;
    z-index: 999;
    transition: all 1s ease 0s;
}
.containerNoCollapse{
	max-width: 70px;
	transition: width 0.9s ease 0s;
} 
.logoContainer{
  height: 96px;
  max-height: 96px;
}
#nav-movilizate-tab{
	padding: 8px 9px 8px 16px;
	border-bottom:none;
	margin-left: 0px;
	top:0px;
	opacity: 1;
}
.displayNone {
	display: none !important;
} 
li.nav-item.dropdown{
	position:fixed;
	right:20px;
	list-style:none;
	top:-5px;
	background: white;
	z-index: 200;
}
.dropdown-menu {
    min-width: 12rem;
}
#nav-usuario-tab{
	width: 250px;
}
#userInfoContainer{
	display: flex;
}
#userIcon{
	width: 20%;
	padding-right: 10px;
}
#userInfo{
	width: 80%;
}
#userName{
	font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #00131DCC;
    text-align: left;
}
#serviceName{
	font-size: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #00131DCC;
    text-align: left;
}

#userIcon svg{
	color: #00131dcc !important;
	font-size: 33px;
}
.dropdown-menu svg{
	color: #001188 !important;
    padding-right: 5px;
}
div.dropdown-menu{
	box-shadow: 0px 8px 6px 0px #6c757d;
    position: absolute;
    transform: translate3d(0px, 58px, 0px);
    top: 10px;
    left: 0px;
    will-change: transform;
	width: 75%;
    border: none;
    border-radius: 10px;
}
.dropdown-item{
	background-color: initial;
    border: 0;
    clear: both;
	color: #212529 !important;
    display: block !important;
    font-weight: 400 !important;
    padding: 0.25rem 1.5rem;
	text-align: inherit !important;
    text-decoration: none;
    white-space: nowrap;
    width: 100%;
	padding-top: 0px !important;
}
.dropdown-item span{
	font-size: 14px;
    color: gray;
    min-width: 60%;
}


@keyframes App-logo-spin {
	from {
	  transform: rotate(0deg);
	}
	to {
	  transform: rotate(360deg);
	}
  }
  .modal-title{
	display: contents;
  }
  .close{
	background: transparent !important;
	color: white;
    opacity: 0.7;
	border: none;
	float: right;
  }
  .close:hover{
	opacity: 1;
  }
  .modal-content{
	border-radius: 20px !important;
  }
  .modalHeader{
	border: 1px solid #001188;
	background: #001188;
	color: white;
	font-weight: bold;
	display: block;
	border-radius: 19px 19px 0px 0px !important;
  }
  .modalBody{
	max-height: 74vh;
	overflow: auto;
  }

  .modal.fade.show{
	overflow-y: hidden;
  }

  .buttonMzatePrimary{
	padding: 3px 15px 6px 15px;
	height: 27px;
	background-color: #8DBE22;
	cursor: pointer;
	border: none;
	border-radius: 8px;
	color: #060401;
	font-weight: bold;
	font-size: 14px;
  }
  .buttonMzatePrimary:hover{
	background: #001188;
	color: white;
  }
  .buttonMzateSecondary{
	padding: 3px 15px 6px 15px;
	height: 27px;
	background-color: #00131D26;
	cursor: pointer;
	border: none;
	border-radius: 8px;
	color: #060401;
	font-weight: bold;
	font-size: 14px;
  }
  .buttonMzateSecondary:hover{
	background: #001188;
	color: white;
  }
 
  button svg{
	margin-bottom: 3px;
	margin-right: 3px;
  }

  .table .cellgroup{
	border-left: 1px solid #bfbfbf;
  } 
  .table th{
	background: #f5f5f5 !important;
	text-align: center;
	font-size: 0.8rem;
	border-top: 0.1px solid lightgray;
  } 
  .table-hover>tbody>tr:hover>* {
	background: #f5f5f5 !important;
	--bs-table-accent-bg: #f5f5f5 !important;
  } 
  .table tr{
	font-size: 13px;
	color: #4d4d4d;
  } 

  .content{
	font-size: 12px;
  } 

  .selectStandard{
	border-radius: 10px !important;
    font-size: 12px !important;
    height: 27px;
  } 
  .contentInput{
	display: inline-flex;
  }
  .table-dark th{
	background-color: #343a40;
	text-align: left;
	border-top: none;
  } 
  .cellOptions{
	cursor: pointer;
  }  
  .table tr th.thDescription{
	vertical-align: middle;
	border-left: .1px solid #d3d3d3;
  } 
  .table tr td.cellValue{
	border-left: .1px solid #d3d3d3;
  } 
  .addContent{
	cursor: pointer;
  } 
  .form-group{
	margin-bottom: 0.5rem;
  } 
  .arrayStringValue{
	display: inline-flex;
	margin: 5px;
  } 
  .removeContent{
	margin-right: 5px;
	cursor: pointer;
  } 
  select.newValueHour{
	border-color: darkgray;
    border-radius: 10px;
	width: 65px;
  }
  select.newValueMinute{
	border-color: darkgray;
    border-radius: 10px;
	width: 65px;
  }
  .divShowTable .table>:not(:first-child){
	border-top: 1px solid darkgrey;
	border-bottom: 1px solid darkgrey;
  }
  .css-1s2u09g-control{
	border-radius: 10px !important;
	min-height: 27px !important;
	height: 27px !important;
	font-size: 13px !important;
  }
 
  .css-1pahdxg-control{
	border-color: #001188 !important;
    border-radius: 10px !important;
	height: 27px !important;
	min-height: 27px !important;
	font-size: 13px !important;
  }
  .css-1wy0on6{
	height: 25px !important;
  }

  .bodyContent .react-calendar-timeline .rct-header-root{
	background: #001188;
  }

  .css-26l3qy-menu{
	z-index: 20000 !important;
  }

  .ol-map {
	height: 100%;
	width: 100%;
  }
  .ol-control {
	position: absolute;
	background-color: rgba(255,255,255,0.4);
	border-radius: 4px;
	padding: 2px;
  }
  .ol-full-screen {
	top: .5em;
	right: .5em;
  }

  .layer-switcher.shown.layer-switcher-activation-mode-click {
	padding-right: 34px;
  }
  .layer-switcher.shown.layer-switcher-activation-mode-click > button {
	right: 0;
	border-left: 0;
  }

  @keyframes spinner {
	to {
	  transform: rotate(360deg);
	}
  }
  .spinner:after {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 55%;
    left: 65%;
	width: 40px;
	height: 40px;
	margin-top: -20px;
	margin-left: -20px;
	border-radius: 50%;
	border: 5px solid rgba(180, 180, 180, 0.6);
	border-top-color: rgba(0, 0, 0, 0.6);
	animation: spinner 0.6s linear infinite;
  }

  .ol-popup {
	position: absolute;
	background-color: white;
	box-shadow: 0 1px 4px rgba(0,0,0,0.2);
	padding: 15px;
	border-radius: 10px;
	border: 1px solid #cccccc;
	bottom: 12px;
	left: -50px;
	min-width: 280px;
  }
  .ol-popup:after, .ol-popup:before {
	top: 100%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
  }
  .ol-popup:after {
	border-top-color: white;
	border-width: 10px;
	left: 48px;
	margin-left: -10px;
  }
  .ol-popup:before {
	border-top-color: #cccccc;
	border-width: 11px;
	left: 48px;
	margin-left: -11px;
  }
  .ol-popup-closer {
	text-decoration: none;
	position: absolute;
	top: 2px;
	right: 8px;
	cursor: pointer;
  }
  .ol-popup-closer:after {
	content: "✖";
  }
  .taskBox{
	padding-bottom: 10px;
	border-bottom: 1px solid gray;
	cursor: pointer;
	padding-left: 10px;
  } 
  .divTaskStatus{
    padding: 5px;
    border-radius: 6px;
    font-weight: bolder;
	margin-left: 2px;
	cursor:pointer;
  }
  .divTaskStatusInactive{
	opacity: 0.6;
  } 
  .bolder{
	font-weight: bolder;
  } 

  .react-calendar-timeline .weekend{
	color: #060401 !important;
	font-weight: bolder;
	white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  } 

  .markersInfo{
	position: absolute;
    bottom: 4px;
    z-index: 999;
    background-color: #f0f7e8;
    border-top: 1px solid #bfbfbf;
    border-right: 1px solid #bfbfbf;
    padding: 5px 5px 5px 5px;
    text-align: center;
	display: flex;
  }
  #markersImgDiv{
	display: flex;
  }
  .ol-attribution{
	display: none !important;
  }
  .ol-rotate{
	display: none !important;
  }
  .activeItem{
	background: #D2E4CF;
	cursor: pointer;
	font-weight: bold;
	border-radius: 5px 5px 0px 0px;
  } 
  .taskDetailToShow{
	text-align: center;
	background: lightgray;
	cursor: pointer;
	margin-bottom: 5px;
	border-radius: 5px;
  } 

  .dateTimeInput{
	border-radius: 5px !important;
    font-size: 12px !important;
    height: 27px;
	border: thin solid lightgray !important;
  } 
  #containerReport{
	display: none;
    position:absolute;
    top: 2020px;
  } 

 .taskStatus{
	height: 10px;
	width: 10px;
	border-radius: 50px;
	display: inline-block;
}
.taskDetailToShow svg{
	margin-top: 4px;
}
.detailTask{
	display: block;
    border: 1px solid lightgray;
    padding: 5px;
    border-top: none;
    border-radius: 5px;
}
.taskDetailPhoto{
	max-height: 150px !important;
	width: auto;
    height: auto;
} 
.dropdown-item:hover {
    background: #a1d06d !important;
    cursor: pointer;
}


.dropdownModulesCollapse{
    position: relative !important;
    right: 0 !important;
    width: 10px !important;
    cursor: pointer !important;
    height: 70px !important;
}

.dropdown-modulesCollapse{
	position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
}

.dropdown-modulesCollapseShow{
	width: 190px;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 8px 6px 0px #6c757d;
    display: block;
    position: absolute;
    transform: translate3d(0px, 57px, 0px);
    top: 4px;
    left: 0px;
    will-change: transform;
}

.dropdown-modulesCollapseShow a.active{
	border-bottom: none !important;
}

.boxList{
	border: 1px solid #072776;
    border-radius: 25px;
    text-align: center;
	margin: 5px;
	padding: 10px;
	cursor: pointer;
	font-size: 15px;
} 

.boxList:hover{
	background: #8DBF2633;
}

.imgThumbnail{
	border: 1px solid #ddd; 
	border-radius: 4px;  
	padding: 5px; 
} 
.imgThumbnail:hover .optionImgThumbnail{
	visibility: inherit;
}

.imgThumbnail:hover{
	box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.imgThumbnail:hover .optionImgThumbnailSelect{
	visibility: inherit;
}

.optionImgThumbnail{
	visibility: hidden;
	display: flex;
	justify-content: space-around;
	opacity: 0.6;
	transition: opacity 0.3s linear, top .3s ease-out;
	box-shadow: 0 3px 7px rgb(0 0 0 / 30%)
}

.optionImgThumbnailNoHover{
	visibility: hidden;
	display: flex;
	justify-content: space-around;
	padding: 15px;
	opacity: 0.6;
	transition: opacity 0.3s linear, top .3s ease-out;
	box-shadow: 0 3px 7px rgb(0 0 0 / 30%)
}

.divOptionImgThumbnail{
	padding: 10px;
	cursor: pointer;
} 

.divOptionImgThumbnail:hover{
	background-color: #e9ecef;
} 

.optionImgThumbnailSelect{
	visibility: hidden;
	position: relative;
	background: transparent;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 25px;
	letter-spacing: 2px;
    border: 1px solid;
    border-color: #060401;
    padding: 9px 10px;
	width: 20px;
    font-size: 1px;
	cursor: pointer;
}

.iconCheckedImgThumbnail{
	position: absolute;
    top: 1px;
    left: 3px;
	color: black;
    font-size: 15px;
}

#react-paginate ul {
	margin-left: 20px;
	padding-left: 0;
	justify-content: center;
 }
 
 #react-paginate li {
	display: inline-block;
	border: 1px solid rgb(224, 224, 224);
	color: #000;
	cursor: pointer;
	margin-right: 3px;
	border-radius: 5px;
	margin-bottom: 5px;
	padding: 4px;
	font-size: var(--bs-pagination-font-size);
	position: relative;
    text-decoration: none;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
 }
 
  #react-paginate li a {
	  padding: 2px 5px;
	  display: inline-block;
	  color: #000;
	  outline: none;
	  text-decoration: none;
  }
 
 #react-paginate li.active {
	background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color);
    color: var(--bs-pagination-active-color);
    z-index: 3;
 }

 #react-paginate li.active a{
	color: white;
 }


 #react-paginate li:hover:not(.active) {
	background-color: var(--bs-pagination-hover-bg);
	border-color: var(--bs-pagination-hover-border-color);
    color: var(--bs-pagination-hover-color);
    z-index: 2;
 }

 #react-paginate li.disabled{
	pointer-events: none;
 }
 #react-paginate li.disabled a{
	pointer-events: none;
 }

 .storageNumber{
    background: #072776;
    color: white;
    padding: 15px;
    width: 85px;
    border-radius: 10px;
    height: 78px;
    font-size: 30px;
    text-align: center;
 } 

 .storageListContainer{
    border: 1px solid #072776;
    border-radius: 15px;
 } 

.dropdown-toggle::after {
    color: #072776;
    margin-top: -25px;
    margin-right: 256px;
    float: right;
	font-size: 14px;
}

 .modalLargue{
	max-height: 500px;
 } 

 .playIcon{
	visibility: hidden;
	color:#001188;
	position: relative;
    bottom: 50%;
	cursor: pointer;
	pointer-events: none;
 } 

 .colVideoTutorial:hover .playIcon{
	visibility: visible;
 }
 
 .videoThumbnail{
	border: 1px solid #ddd; 
	border-radius: 15px;  
	cursor: pointer; 
	text-align: center;
    padding: 10px;
    padding-bottom: 30px;
	height: auto;
 } 

 .videoThumbnailImg{
	height: auto;
	max-width: 100%;
 } 

 .videoThumbnail:hover{
	box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
	background: rgba(0,0,0,0.4);
 } 

 .videoThumbnail:hover .videoThumbnailImg{
	opacity: 0.5;
 }

.rowsTypeTask{
	display: none !important;
} 

.css-1pahdxg-control, .css-1s2u09g-control{
	font-size: 12px !important;
} 

.table tr:hover td{
	background: #cee7b3 !important;
	--bs-table-accent-bg: #cee7b3 !important;
}

.noPointerEvents{
	pointer-events: none;
} 

.divNoResultsMzate-independent{
	background-image: url('img/independent/flechas.png');
	background-repeat: no-repeat;
    background-size: contain;
    text-align: center;
    background-position: center;
	min-height: 200px;
}

.divNoResultsMzate-cast_mzate{
	background-image: url('img/cast_mzate/flechas.png');
	background-repeat: no-repeat;
    background-size: contain;
    text-align: center;
    background-position: center;
	min-height: 200px;
} 

.bg-taskStatusInprocess{
	background-color: rgb(147, 204, 234) !important;
} 

.bg-taskStatusFinished{
	background-color: rgb(141, 190, 34) !important;
}

.bg-taskStatusPending{
	background-color: #e9ecef !important;
	color: #212529!important;
}  

.dropdown-container{
	border-radius: 10px!important;
    font-size: 12px!important;
    height: 27px!important;
    min-height: 27px!important;
} 

.dropdown-heading{
	height: 27px!important;
    min-height: 27px!important;
}

.rct-scroll{
	z-index: 0;
} 
#nav-tab #tutorialVideos{
	pointer-events: none;
} 

.modalContentDefaultStorage{
    height: 30vw;
    max-height: 28vw;
}


.popup {
	position: absolute;
    bottom: 15px;
	width: 300px;
	margin: 50px auto;
	background: #018;
	padding: 20px;
	text-align: center;
	font-weight: 900;
	color: #fff;
	border-radius: 10px;
	font-size: 13px;
	display: none;
  }

  .popupDiv:before {
	content: "";
	width: 0px;
	height: 0px;
	position: absolute;
	border-left: 10px solid #018;
	border-right: 10px solid transparent;
	border-top: 10px solid #018;
	border-bottom: 10px solid transparent;
	left: 19px;
	bottom: -19px;
  }

  #divTitle .title{
	font-size: 14px;
	color: #072776;
	font-weight: bold;
  }  

  .mzatePhasesContainerOptions{
	border-bottom: 1px solid darkgray;
    text-align: center;
    margin-bottom: 10px;
  } 

  .mzatePhasesContainerOptions .col:hover{
	background: whitesmoke;
  } 

  .modalContainerWithOptionsPhases{
	padding-top: 0px !important;
  } 

  .cursorNone{
	cursor: none !important;
  } 

  .billingFaqOptions{
	position: relative;
    background: var(--semantic-background-canvas);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    border: 1px solid lightgray;
    -webkit-box-shadow: 0px 4px 4px rgba(218, 218, 218, 0.25);
    box-shadow: 0px 4px 4px rgba(218, 218, 218, 0.25);
    margin-bottom: 10px;
    padding: 20px;
    font-size: 1rem;
	cursor: pointer;
  } 

  .billingFaqAnswer{
	padding-top: 10px;
    border-top: 1px solid #E5E5E5;
    font-size: calc(1rem + 0.125 * (100vw - 23.438rem) / 96.562);
    color: black;
  } 

  .inputWidth94{
	width: 93% !important;
	display: inline-block !important;
  } 

  .colWithMarginBottom{
	margin-bottom: 5px;
  } 

  .removeContentImg{
	cursor: pointer;
    margin-left: 1%;
  } 
  .economicActivitiesContent{
	padding: 5px;
    border: 1px solid gray;
    border-radius: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
  } 

  .inputFile{
	height:33px !important;
    border-radius:10px !important;
}

.userForStamp{
	border: 1px solid gray;
    padding: 5px;
    border-radius: 10px;
	margin-top: 10px;
} 

.inactiveItem{
	background: lightgray;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
    font-weight: 700;
} 

.imgContaninerForShow{
	height: 300px;
    max-width: 700px;
    margin-top: 20px;
} 

.imgForShow{
	max-height: 300px;
    display: block;
    max-width: 100%;
}

.productItems{
	border: 1px solid #001188;
    margin: 5px;
    padding: 5px 5px 5px 5px;
    border-radius: 10px;
	cursor: pointer;
} 

.productItems:hover{
	background-color: #D2E4CF;
} 

.priceListDiv{
	border: 1px solid #8DBE22 !important;
    border-radius: 20px;
    text-align: center;
	padding: 5px;
	cursor: pointer;
} 

.priceListTotalDiv input {
    text-align: center;
	pointer-events: none;
}

.priceListDiv:hover {
    background: #D2E4CF;
}

.productDiv{
	border: 1px solid #8DBE22 !important;
    border-radius: 20px;
    padding: 5%;
    margin: 2px;
} 

.productDiv:hover {
    background: #D2E4CF;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

.officeToAddOrRemove li{
	border-bottom: 1px solid silver;
    margin: 0.8em 0.1em;
    padding: 0.8em 0.1em;
    text-decoration: none;
    position: relative;
    transition-duration: 0.5s, 0.5s;
    transition-delay: 0s, 0s;
	list-style: none;
} 

.officeToAddOrRemove{
	padding-left: 0;
} 


.iconSubmenu{
font-size: 5px !important;
margin-left: 15px;
}

.arrowIcon{
font-size: 15px !important;
}

.title{
font-weight: bold;
color: #072776;	
} 

.cellTitle{
font-weight: bolder;
color: #060401;
} 

.sc-gKXOVf span{
margin-left: 25px !important;
text-align: justify;
pointer-events: none;
}

.sc-gKXOVf svg{
pointer-events: none;
} 

.mzatePaginationClass{
float: right;
} 

.spanIconShowHidePass{
position: absolute;
right: 20px;
top: 50%;
transform: translateY(-50%);
cursor: pointer;
} 

.detailTotalInvoice{
background: #072776;
color: white;
border-radius: 15px;
text-align: center;
} 


.isiKwY{
background: #060401 !important;
text-align: left !important;
} 

.subMenuItemSideMenu{
margin-left: 30px !important;
width: 120px !important;
white-space: nowrap !important;
text-overflow: ellipsis !important;
overflow: hidden !important;
pointer-events: none;
} 

.modalWithOptionsItem{
padding-top: 0 !important;
}

table#portfolio {
    border-collapse: collapse;
    font-size: 12px;
}

.top-1 {
    background: #A1D06D;
}

table#portfolio tbody tr td.ion {
    padding: 3px;
}

table#portfolio tbody tr td, table#portfolio thead tr td {
    vertical-align: top;
    padding: 5px;
}

.top-1 td, .medium-1 td, .medium-2 td, .medium-3 td, .bottom-1 td {
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    padding: 10px 5px 5px 5px;
}

.medium-1 {
    background: #B2D78A;
}

table#portfolio tbody tr td label {
    cursor: pointer;
    margin: 0px !important;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

table#portfolio tbody tr td ul {
    padding-left: 5px;
}

.top-1 td ul, .medium-1 td ul, .medium-2 td ul, .medium-3 td ul, .bottom-1 td ul {
    list-style: none;
}

.medium-2 {
    background: #CEE7B3;
}

.medium-3 {
    background: #DFEFCD;
}

.bottom-1 {
    background: #f0f7e8;
}

table#portfolio tbody tr td.ion p {
    text-align: center;
}

table#portfolio tbody tr td p {
    vertical-align: top;
    margin-bottom: 0px;
}

table#portfolio tbody tr td.ion label {
    margin: 0;
    text-transform: uppercase;
}

.top-1 td p span, .medium-1 td p span, .medium-2 td p span, .medium-3 td p span, .bottom-1 td p span {
    font-weight: bold;
    text-align: center;
}

.thresholdAlertYellow{
	color: #fea604 !important;
    font-weight: bolder;
} 

.thresholdAlertRed{
	color: #f70000 !important;
    font-weight: bolder;
} 

#userIcon i:hover #notificationCount{
	background:#060401;
}

#notificationCount{
	position: relative;
    right: 11px;
    color: white;
    font-size: 13px;
    bottom: 13px;
    background: #001188;
    border-radius: 25px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.eventNotificationRow{
	padding-top:10px;
	padding-bottom:10px;
	margin-bottom: 2px;
}

.eventNotificationRowOpen{
	background:#DFEFCD
}

.eventNotificationRow:hover{
	background:#cee7b3 !important;
	cursor:pointer;
}

.unreadNotification{
	background:gainsboro
}

.invoiceShowContainer{
	overflow-y: auto;
    max-height: 100vh;
    overflow-x: hidden;
} 
 
.sendNowEDocumentButton{
	background: #00131d26;
	padding: 3px;
    border-radius: 5px;
    cursor: pointer;
	font-weight: bolder;
} 

.sendNowEDocumentButton:hover{
	background-color:#001188 !important;
	color:white !important;
} 

.requiredFieldsIcon{
	color: #8dbe22;
	font-weight: bolder;
} 

.highlight {
	background-color: rgba(173, 216, 230, 0.5);
  	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); 
  	transition: background-color 0.5s ease, box-shadow 0.5s ease; 
}
.iconDownloadStandard:hover{
	color: #018;
}

td.sub_table-container{
	background-color: #bfeafa !important;
    cursor: default;
} 

.subTableBody{
	background: white;
}

table.without_margin{
	margin: 0 !important;
}

.table .tr_sub_table-container:hover td.sub_table-container {
    background-color: #bfeafa !important;
	--bs-table-accent-bg: #bfeafa !important;
} 

.sidebar2 .submenu a{
	padding-left: 15px;
} 
